import React from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Table,
  Input
} from 'reactstrap'


class TagModals extends React.Component {
  constructor (props) {
    super(props)
    const end_date = this.props.tag.end_date ? new Date(this.props.tag.end_date).toISOString().split('T')[0] : "N/A"
    const active = this.props.tag.active === null ? "N/A" : this.props.tag.active === true ? "TRUE" : "FALSE"
    this.state = {
      cost_center: this.props.tag.cost_center,
      network: this.props.tag.network,
      wbs: this.props.tag.wbs,
      project_id: this.props.tag.project_id,
      sap_project_name: this.props.tag.sap_project_name,  
      ppm_project_name: this.props.tag.ppm_project_name,
      end_date: end_date !== "N/A" ? end_date : '2099-12-31T01:00:00.000Z', //if this is still here in 2099, we have bigger problems. My condolences.  
      active: active,
    }
    this.toggle = this.toggle.bind(this);
    this.doSubmit = this.doSubmit.bind(this);
    this.auth = this.props.auth;
    this.axiosClient = this.props.axiosClient;
  }

  doSubmit () {
    console.log(this.state);
    const submission_data = {
      project_id: this.state.project_id,
      sap_project_name: this.state.sap_project_name,
      ppm_project_name: this.state.ppm_project_name,
      active: this.state.active,
      end_date: new Date(this.state.end_date).toISOString().split('T')[0]
    }
    this.axiosClient.post('/tools/tag_update', submission_data)
      .then(resp => {
        console.log(resp)
        alert('Project Successfully Updated')
        window.location.reload(false)
      })
      .catch(err => {
        console.error(err)
      })
  }

  toggle () {
    this.setState({
      modal: !this.state.modal
    })
  }

  render () {
    const tableStyles = {
      color: 'white',
      backgroundColor:'rgba(27, 32, 99, 0.9)',
      borderColor:'rgb(0, 123, 255)',
      borderRadius: "5px",
    }

    const txtColor = {
      color:'white',
    }

    return (
      <>
        {
          this.state.isLoading &&
          <Spinner> </Spinner>
        }
        {
          !this.state.isLoading &&
          <div>
            <Button color="secondary" style={{maxWidth: '230px', borderRadius: "24px", padding: "2px 20px",textAlign: "center",marginBottom: '50px',cursor: "pointer"}} onClick={this.toggle}>Edit</Button>
            <Modal size="xl" style={{maxWidth: '800px', width: '100%', color:'white'}} isOpen={this.state.modal} toggle={this.toggle} className={this.props.tag.project_id}>
              <ModalHeader toggle={this.toggle} style={{color:'white', backgroundColor:'rgba(34, 46, 107, 1 )'}}>Edit {this.props.tag.project_id}</ModalHeader>
                <ModalBody style={{backgroundColor:'rgba(34, 46, 107, 1)', textColor:'white'}}>
                    <Table style={tableStyles}>
                      <thead>
                        <tr>
                          <th style={txtColor}>Info</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr><td>{this.state.cost_center}</td></tr>
                        <tr><td>{this.state.network}</td></tr>
                        <tr><td>{this.state.wbs}</td></tr>
                        <tr><td>{this.state.project_id}</td></tr>
                        <tr>
                          <td>
                            SAP Project Name: <Input type="text" value={this.state.sap_project_name} onChange={(e) => this.setState({sap_project_name: e.target.value})} />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            PPM Project Name: <Input type="text" value={this.state.ppm_project_name} onChange={(e) => this.setState({ppm_project_name: e.target.value})} />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <>
                              End Date: <Input type="date" value={new Date(this.state.end_date).toISOString().split('T')[0]} onChange={(e) => this.setState({end_date: e.target.value})}/>
                            </>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <>
                              Active?: <Input type="select" value={this.state.active} onChange={(e) => this.setState({active: e.target.value})}>
                                <option value="TRUE">TRUE</option>
                                <option value="FALSE">FALSE</option>
                                <option value="N/A">N/A</option>
                              </Input>
                            </>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter style={{backgroundColor:'rgba(34, 46, 107, 1)'}}>
                  <Button color="primary" onClick={this.toggle} style={{maxWidth: '230px',borderRadius: "24px",padding: "2px 20px",textAlign: "center",cursor: "pointer"}}>Close</Button>
                  <Button color="success" onClick={this.doSubmit} style={{maxWidth: '230px',borderRadius: "24px",padding: "2px 20px",textAlign: "center",cursor: "pointer"}}>Submit</Button>

                </ModalFooter>
            </Modal>
          </div>
        }
      </>
    )
  }
}

export default TagModals